.header-container {
    white-space: nowrap;
    display: flex;
    background-color: #85a0a8;
    max-width: 100%;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.link {
    text-decoration: none;
    color: rgb(42, 40, 40);
    display: flex;
    align-items: center;
    min-width: 0;
}

.logo {
    margin-left: 50px;
    margin-top: 5px;
    width: 50px;
    /* Ширина */
}

.login-button {
    margin-right: 50px;
    margin-left: 10px;
}

.logo-name {
    font-family: 'Roboto', sans-serif;
    font-size: larger;
    margin-left: 5px;
}

.header-menu-base{
    margin-top: 10px;
    margin-left: 50px;
    display: flex;
    justify-content: space-between;
    margin-right: 50px;
}

